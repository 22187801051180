import { environmentBase } from './enviroment.base';

const env: Partial<typeof environmentBase> = {
  dataENV: 'DEV',
  api: {
    url: 'https://dev.nationaltypeapproval.uk.devtest.aws.scania.com/api',
  },
};

export const environment = Object.assign(environmentBase, env);
