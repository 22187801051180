import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DVSRequestDTO,
  convertToCSVFormData,
} from '../models/dvs-request/dvs-request-dto.model';

@Injectable({
  providedIn: 'root',
})
export class DVSRequestService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}/DVSRequest`;

  constructor(private http: HttpClient) {}

  post(data: DVSRequestDTO): Observable<any> {
    this.cancelPrevious();

    return this.http
      .post<any>(this.apiUrl, data, { responseType: 'json' })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  postCSV(data: DVSRequestDTO): Observable<any> {
    this.cancelPrevious();

    const formData = convertToCSVFormData(data);

    return this.http
      .post<any>(this.apiUrl + '/CSV', formData, {
        responseType: 'json',
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<any>(`${this.apiUrl}/UploadList`, formData)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
