import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular';
import { ToastService } from '../../services/toast.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
  imports: [CommonModule, TegelModule],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class ToastComponent {
  constructor(public toast: ToastService) {}

  toastClosed() {
    this.toast.dismissToast();
  }
}
