import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TegelModule } from '@scania/tegel-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { ToastComponent } from './core/components/toast/toast.component';
import { CSVUploadComponent } from './features/request/csv-upload/csv-upload.component';
import { IndividualComponent } from './features/request/individual/individual.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    TegelModule,
    HeaderComponent,
    FooterComponent,
    IndividualComponent,
    AppRoutingModule,
    ToastComponent,
    BrowserAnimationsModule,
    NgxFileDropModule,
    CSVUploadComponent,
    FormsModule,
  ],
  providers: [provideHttpClient()],
  bootstrap: [AppComponent],
})
export class AppModule {}
