<div id="header">
  <h2 class="tds-headline-02">Your request is being processed</h2>
  <h3 class="tds-headline-03">Request Id: {{ requestId }}</h3>
  <p class="tds-paragraph-03">
    Thank you for submitting your request for the Direct Vision Standard Star
    Rating.
  </p>
  <p class="tds-paragraph-03">
    We are currently generating the PDF report for your vehicle(s). You will
    receive the report via email once the process is complete.
  </p>
  <p class="tds-paragraph-03">
    Please check your spam or junk folder if you do not see the email in your
    inbox.
  </p>
  <p class="tds-paragraph-03">
    If you have any questions or need further assistance, please contact us at:
    <a
      href="mailto:dvs&#64;scania.com?subject=DVS%20Request%20Id%3A%20{{
        requestId
      }}%20Query"
      >dvs&#64;scania.com</a
    >
  </p>
  <p class="tds-paragraph-03">Thank you for your patience.</p>
</div>

<tds-button
  class="tds-u-pb3 tds-u-pt2"
  variant="primary"
  size="sm"
  text="Start a New Request"
  [routerLink]="''"
></tds-button>
