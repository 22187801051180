<div id="header" class="tds-u-pb1">
  <h2 class="tds-headline-02">Contact Details</h2>
  <p class="tds-paragraph-03">
    Please provide your name & email address in the fields below.
  </p>
  <p class="tds-paragraph-03">
    We need this information to send you the PDF report of your vehicle(s)
    Direct Vision Standard Star Rating.
  </p>
  <p class="tds-paragraph-03">
    Your contact details ensure that you receive your report promptly and allow
    us to reach you if there are any issues with your request.
  </p>
</div>

<tds-divider orientation="horizontal"></tds-divider>

<form class="tds-u-pb3 tds-u-pt2" (submit)="handleSubmit($event)">
  <div class="tds-row">
    <div class="tds-col-md-6 tds-col-xs-12">
      <tds-text-field
        type="Text"
        name="name"
        label="Name / Company*"
        label-position="outside"
        max-length="255"
        [helper]="formState.nameError"
        [state]="formState.nameState"
        [disabled]="isBusy"
      >
      </tds-text-field>
    </div>
    <div class="tds-col-md-6 tds-col-xs-12">
      <tds-text-field
        type="text"
        name="email"
        label="Email Address*"
        label-position="outside"
        [helper]="formState.emailError"
        [state]="formState.emailState"
        [disabled]="isBusy"
      >
      </tds-text-field>
    </div>
    <div class="tds-col-md-2 tds-col-xs-12">
      <tds-button
        fullbleed
        type="submit"
        class="tds-u-mt3"
        variant="primary"
        text="Submit"
        size="md"
        [disabled]="isBusy"
      ></tds-button>
    </div>
  </div>
</form>
