import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactDetailsComponent } from './features/request/contact-details/contact-details.component';
import { CSVUploadComponent } from './features/request/csv-upload/csv-upload.component';
import { IndividualComponent } from './features/request/individual/individual.component';
import { SubmittedComponent } from './features/request/submitted/submitted.component';

const routes: Routes = [
  { path: '', component: IndividualComponent },
  { path: 'multiple', component: CSVUploadComponent },
  { path: 'contactdetails', component: ContactDetailsComponent },
  { path: 'submitted', component: SubmittedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
