export class CSVRowError {
  row: number;
  registration?: string[][];
  vin?: string[][];

  constructor(row: number, registration?: string[][], vin?: string[][]) {
    this.row = row;
    if (registration) this.registration = registration;
    if (vin) this.vin = vin;
  }
}
