<div id="header" class="tds-u-pb1">
  <h2 class="tds-headline-02">Vehicle List Upload</h2>
  <p class="tds-paragraph-03">
    Please complete the below CSV template with vehicle data, and upload. Ensure
    that the uploaded template contains the VIN’s (and if available the
    registration numbers) of the vehicles for which you are requesting the
    Direct Vision Standard Star Ratings.
  </p>
  <p class="tds-paragraph-03">
    This will allow us to accurately process your request and generate the
    necessary report, listing each vehicle.
  </p>
</div>

<div class="tds-col-md-12 tds-u-pb3">
  <app-vin-info></app-vin-info>
</div>

<div class="tds-col-md-8 tds-col-sm-12 tds-col-xs-12 tds-u-pb3">
  <tds-block mode-variant="primary">
    <p class="tds-paragraph-03 filedrop-text-color">
      You can submit a list of vehicles, up to a maximum of <b>1000</b>.
    </p>
    <p class="tds-paragraph-03 filedrop-text-color">
      Acceptable file types: <b>.CSV</b>
    </p>

    <p class="tds-paragraph-03 tds-u-pb2 filedrop-text-color">
      <tds-button
        type="button"
        variant="secondary"
        size="sm"
        text="Download Template CSV"
        (click)="downloadExampleCSV()"
      >
      </tds-button>
    </p>
    <div class="tds-col-md-12 tds-u-p0">
      <ngx-file-drop
        [directory]="false"
        [multiple]="false"
        dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event)"
        [multiple]="false"
        accept=".csv"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <div class="tds-container tds-u-pb3 tds-u-pt2">
            <div class="tds-col-md-12 filedrop-text-color tds-u-pb2">
              <span>{{
                files.length > 0
                  ? "File Uploaded: " + files[0].relativePath
                  : "Drag a file here or press the Browse button to upload."
              }}</span>
            </div>
            <div class="tds-row">
              <div class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                <tds-button
                  type="button"
                  variant="primary"
                  size="sm"
                  text="Browse"
                  (click)="openFileSelector()"
                  [disabled]="isBusy"
                >
                </tds-button>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
  </tds-block>
</div>

<div class="tds-row tds-u-pb3" *ngIf="csvError">
  <div class="tds-col-md-12">
    <tds-message variant="error" [header]="csvHeaderError">
      {{ csvError }}
    </tds-message>
  </div>
</div>

<div *ngIf="rowErrors.length">
  <div class="tds-row tds-u-pb3">
    <div class="tds-col-md-12">
      <tds-message variant="error" header="CSV File Issue">
        There are issues with the CSV file. Please correct them and re-upload
        the file.
      </tds-message>
    </div>
  </div>

  <div class="tds-row tds-u-pb3">
    <div class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
      <tds-table responsive no-min-width>
        <tds-table-header>
          <tds-header-cell
            cell-key="csvRow"
            cell-value="Row Number"
          ></tds-header-cell>
          <tds-header-cell
            cell-key="vin"
            cell-value="Vehicle Identification Number"
          ></tds-header-cell>
          <tds-header-cell
            cell-key="reg"
            cell-value="Vehicle Registration"
          ></tds-header-cell>
        </tds-table-header>
        <tds-table-body>
          <ng-container *ngFor="let error of rowErrors">
            <tds-table-body-row>
              <tds-body-cell
                cell-key="csvRow"
                [attr.cell-value]="error.row + 2"
              ></tds-body-cell>
              <tds-body-cell
                cell-key="vin"
                [attr.cell-value]="error.vin"
              ></tds-body-cell>
              <tds-body-cell
                cell-key="reg"
                [attr.cell-value]="error.registration"
              ></tds-body-cell>
            </tds-table-body-row>
          </ng-container>
        </tds-table-body>
      </tds-table>
    </div>
  </div>
</div>

<div class="tds-row tds-u-pb3">
  <div class="tds-col-md-12">
    <tds-button
      variant="secondary"
      text="Cancel"
      [routerLink]="''"
    ></tds-button>
    <tds-button
      class="tds-u-ml3"
      variant="primary"
      text="Next"
      [routerLink]="'/contactdetails'"
      *ngIf="fileValid"
      ><tds-icon
        slot="icon"
        class="tds-u-ml1"
        size="15px"
        name="chevron_right"
      ></tds-icon
    ></tds-button>
  </div>
</div>
