import { DVSRequestVinDTO } from './dvs-request-vin-dto.model';

export interface DVSRequestDTO {
  name: string;
  email: string;
  CSVFile?: File;
  dvsRequestVinDTOs: DVSRequestVinDTO[];
}

export function convertToCSVFormData(dvsRequest: DVSRequestDTO): FormData {
  const formData = new FormData();
  formData.append('name', dvsRequest.name);
  formData.append('email', dvsRequest.email);
  formData.append('dvsRequestVinDTOs', '');

  if (dvsRequest.CSVFile) {
    formData.append('file', dvsRequest.CSVFile, dvsRequest.CSVFile.name);
  }

  return formData;
}
