import { Component } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular';

@Component({
  selector: 'app-vin-info',
  standalone: true,
  imports: [TegelModule],
  templateUrl: './vin-info.component.html',
})
export class VinInfoComponent {}
