import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import { DVSRequestVinDTO } from 'src/app/core/models/dvs-request/dvs-request-vin-dto.model';
import { VinInfoComponent } from '../../shared/vin-info/vin-info.component';
import { RequestService } from '../request.service';

@Component({
  selector: 'app-individual',
  standalone: true,
  imports: [TegelModule, RouterLink, CommonModule, VinInfoComponent],
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css'],
})
export class IndividualComponent {
  dvsRequests: DVSRequestVinDTO[] = [];

  formState = {
    vinState: 'default',
    vinError: '',
    regState: 'default',
    regError: '',
  };

  vehicleInformation: FormGroup = new FormGroup({
    vin: new FormControl('', Validators.required),
    reg: new FormControl(''),
  });

  constructor(private requestService: RequestService) {
    if (!this.requestService.dvsRequestDTO?.dvsRequestVinDTOs?.length) {
      this.requestService.clearDVSRequests();
    } else {
      this.dvsRequests = this.requestService.dvsRequestDTO.dvsRequestVinDTOs;
    }
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    const vinValid = this.vinValid(formData);
    const regValid = this.regValid(formData);

    if (vinValid && regValid) {
      this.dvsRequests.push({
        vin: (formData.get('vin') as string).toUpperCase(),
        registration: (formData.get('reg') as string).toUpperCase(),
      });

      this.requestService.dvsRequestDTO.dvsRequestVinDTOs = this.dvsRequests;
      form.reset();
      this.resetInputValues();
    }
  }

  removeRequest(index: number) {
    this.dvsRequests.splice(index, 1);
  }

  vinValid(formData: FormData): boolean {
    const vinValue = formData.get('vin') as string;

    if (vinValue.length !== 17) {
      this.formState.vinError = 'The VIN must be exactly 17 characters long.';
      this.formState.vinState = 'error';
      return false;
    }

    if (!/^[a-z0-9]+$/i.test(vinValue)) {
      this.formState.vinError = 'The VIN can only contain letters and numbers.';
      this.formState.vinState = 'error';
      return false;
    }

    if (
      this.dvsRequests.some(
        (request) => request.vin.toLowerCase() === vinValue.toLowerCase()
      )
    ) {
      this.formState.vinError =
        'This VIN is already in your request list. Please check and try again.';
      this.formState.vinState = 'error';
      return false;
    }

    this.formState.vinError = '';
    this.formState.vinState = 'default';
    return true;
  }

  regValid(formData: FormData): boolean {
    const regValue = formData.get('reg') as string;

    if (regValue != '' && regValue.trim().length == 0) {
      this.formState.regError =
        'The Registration cannot just contain spaces only.';
      this.formState.regState = 'error';
      return false;
    }

    this.formState.regError = '';
    this.formState.regState = 'default';
    return true;
  }

  get dvsRequestsLimitReached(): boolean {
    return this.dvsRequests.length >= 50;
  }

  // HACK: This is a workaround to reset the input values after form submission.
  // Tegel problem.
  resetInputValues() {
    (<HTMLInputElement>document.getElementById('vinInput')).value = '';
    (<HTMLInputElement>document.getElementById('regInput')).value = '';
  }
}
