import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import {
  FileSystemFileEntry,
  NgxFileDropEntry,
  NgxFileDropModule,
} from 'ngx-file-drop';
import { CSVRowError } from 'src/app/core/models/dvs-request/csv-row-error.model';
import { DVSRequestService } from 'src/app/core/services/dvs-request.service';
import {
  TOAST_VARIANT,
  ToastService,
} from 'src/app/core/services/toast.service';
import { VinInfoComponent } from '../../shared/vin-info/vin-info.component';
import { RequestService } from '../request.service';

@Component({
  selector: 'app-csv-upload',
  standalone: true,
  imports: [
    TegelModule,
    CommonModule,
    NgxFileDropModule,
    RouterLink,
    VinInfoComponent,
  ],
  templateUrl: './csv-upload.component.html',
  styleUrl: './csv-upload.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class CSVUploadComponent {
  isBusy: boolean = false;
  csvError: string = '';
  csvHeaderError: string = '';
  rowErrors: CSVRowError[] = [];
  fileValid: boolean = false;

  public files: NgxFileDropEntry[] = [];

  constructor(
    private requestService: RequestService,
    private dvsRequestService: DVSRequestService,
    private toast: ToastService
  ) {
    this.requestService.clearDVSRequests();
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.resetErrors();
    this.files = [files[0]];
    for (const droppedFile of this.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!file.name.includes('.csv')) {
            this.handleFileError(
              'The file type is not supported. Please choose a .CSV file.',
              'File Error'
            );
          } else if (file.size > 1024 * 1024) {
            this.handleFileError(
              'The file size exceeds the 1MB limit. Please select a smaller file.',
              'File Error'
            );
          } else {
            this.uploadFile(file);
          }
        });
      }
    }
  }

  private handleFileError(message: string, headerError: string = '') {
    this.csvError = message;
    this.csvHeaderError = headerError;
    this.fileValid = false;
    this.files = [];
  }

  uploadFile(file: File) {
    this.isBusy = true;
    this.fileValid = false;

    this.dvsRequestService
      .uploadFile(file)
      .subscribe({
        next: () => {
          this.fileValid = true;
          this.requestService.dvsRequestDTO.CSVFile = file;
        },
        error: (e: any) => {
          const errors = e?.error?.errors;

          if (errors) {
            if (errors.CSVError) {
              this.handleFileError(errors.CSVError, 'CSV Error');
            }

            this.rowErrors = this.convertToCSVRowErrors(errors);
          } else {
            this.toast.showToast(
              TOAST_VARIANT.error,
              'Error',
              'A server error occurred. Please try again later.'
            );
            this.resetErrors();
          }
        },
      })
      .add(() => (this.isBusy = false));
  }

  convertToCSVRowErrors(originalErrors: any): CSVRowError[] {
    const result: CSVRowError[] = [];
    const rowErrors = Object.keys(originalErrors).filter((key) =>
      key.startsWith('RowError')
    );

    rowErrors.forEach((key) => {
      const match = key.match(/\d+/);
      if (!match) return;

      const rowNumber = parseInt(match[0], 10);
      const field = key.split('.')[1];
      const message = originalErrors[key];

      let errorEntry = result.find((entry) => entry.row === rowNumber);
      if (!errorEntry) {
        errorEntry = new CSVRowError(rowNumber);
        result.push(errorEntry);
      }

      if (field === 'Registration') {
        errorEntry.registration = [[message[0]]];
      } else if (field === 'Vin') {
        errorEntry.vin = [[message[0]]];
      }
    });

    return result;
  }

  downloadExampleCSV() {
    window.open('assets/DVSTemplate.csv', '_blank');
  }

  resetErrors() {
    this.files = [];
    this.rowErrors = [];
    this.csvHeaderError = '';
    this.csvError = '';
  }
}
