import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import { RequestService } from '../request.service';

@Component({
  selector: 'app-submitted',
  standalone: true,
  imports: [TegelModule, CommonModule, RouterModule],
  templateUrl: './submitted.component.html',
  styleUrl: './submitted.component.css',
})
export class SubmittedComponent {
  requestId: string | undefined;
  constructor(private requestService: RequestService) {
    if (!this.requestService?.submittedId) {
      this.requestService.routeBackToStart();
    }

    this.requestId = this.requestService.submittedId;
  }
}
