import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const TOAST_VARIANT = {
  error: 'error',
  information: 'information',
  success: 'success',
  warning: 'warning',
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private timeoutId: any;

  public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public toastHeader$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  public toastVariant$: BehaviorSubject<string> = new BehaviorSubject<string>(
    TOAST_VARIANT.error
  );

  constructor() {}

  showToast(
    toastState: string,
    toastHeader: string,
    toastMessage: string,
    dismissToastAfter: number = 5000
  ): void {
    // Cancel existing toast
    if (this.showsToast$.value) {
      clearTimeout(this.timeoutId);
      this.dismissToast();
    }

    this.toastVariant$.next(toastState);
    this.toastHeader$.next(toastHeader);
    this.toastMessage$.next(toastMessage);
    this.showsToast$.next(true);

    if (dismissToastAfter > 0) {
      this.timeoutId = setTimeout(() => {
        this.dismissToast();
      }, dismissToastAfter);
    }
  }

  dismissToast(): void {
    this.showsToast$.next(false);
  }
}
