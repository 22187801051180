import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isProduction: boolean = environment.production;

  constructor(@Inject(DOCUMENT) private document: Document) {
    if (!this.isProduction) {
      this.document.body.setAttribute('data-env', environment.dataENV);
    }
  }
}
