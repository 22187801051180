<tds-message
  variant="information"
  header="VIN Information"
  mode-variant="inherit from parent"
>
  <p>
    The VIN provided should only contain letters and numbers, with no special
    characters.
  </p>
  <p>It typically appears in a format like YS2R6X20007654321.</p>
</tds-message>
