<div
  class="toast-container tds-u-flex tds-u-flex-dir-col tds-u-gap1"
  *ngIf="toast.showsToast$ | async"
  [@slideInOut]
>
  <tds-toast
    [variant]="toast.toastVariant$ | async"
    header="Error"
    (tdsClose)="toastClosed()"
  >
    <div slot="subheader">
      {{ toast.toastMessage$ | async }}
    </div>
  </tds-toast>
</div>
