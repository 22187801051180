<tds-footer>
  <div slot="start">
    <tds-footer-group>
      <tds-footer-item>
        <a
          href="https://www.scania.com/uk/en/home/admin/misc/legal/business-with-scania.html"
          target="_blank"
          >Terms and Conditions</a
        >
      </tds-footer-item>

      <tds-footer-item>
        <a
          href="https://www.scania.com/group/en/home/admin/misc/privacy-statement/cookies.html"
          target="_blank"
          >Cookies</a
        >
      </tds-footer-item>
      <tds-footer-item>
        <a
          href="https://www.scania.com/group/en/home/admin/misc/privacy-statement.html"
          target="_blank"
          >Privacy</a
        >
      </tds-footer-item>
      <tds-footer-item *ngIf="!isProduction">
        <a target="_blank"> v{{ currentApplicationVersion }}</a>
      </tds-footer-item>
    </tds-footer-group>
  </div>
  <div slot="end">
    <tds-footer-group>
      <tds-footer-item>
        <a href="https://www.facebook.com/ScaniaUK" target="_blank">
          <img src="/assets/icons/facebook.svg" alt="facebook" />
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a href="https://www.instagram.com/scaniauk" target="_blank">
          <img src="/assets/icons/instagram.svg" alt="instagram" />
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a href="https://www.linkedin.com/company/scania-uk" target="_blank">
          <img src="/assets/icons/linkedin.svg" alt="linkedin" />
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a href="https://x.com/ScaniaUK" target="_blank">
          <img src="/assets/icons/x-twitter.svg" alt="x-twitter" />
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a href="https://www.youtube.com/@Scania_UK" target="_blank">
          <img src="/assets/icons/youtube.svg" alt="facebook" />
        </a>
      </tds-footer-item>
    </tds-footer-group>
  </div>
</tds-footer>
