import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DVSRequestDTO } from 'src/app/core/models/dvs-request/dvs-request-dto.model';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  public dvsRequestDTO: DVSRequestDTO = <DVSRequestDTO>{};
  public submittedId: string | undefined;

  constructor(private router: Router) {}

  clearDVSRequests(): void {
    this.dvsRequestDTO = <DVSRequestDTO>{};
  }

  routeBackToStart(): void {
    this.clearDVSRequests();
    this.router.navigate(['']);
  }
}
