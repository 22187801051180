import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import { DVSRequestService } from 'src/app/core/services/dvs-request.service';
import {
  TOAST_VARIANT,
  ToastService,
} from 'src/app/core/services/toast.service';
import { RequestService } from '../request.service';

@Component({
  selector: 'app-contact-details',
  standalone: true,
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.css',
  imports: [TegelModule],
})
export class ContactDetailsComponent {
  isBusy: boolean = false;

  formState = {
    nameState: 'default',
    nameError: '',
    emailState: 'default',
    emailError: '',
  };

  constructor(
    private requestService: RequestService,
    private dvsRequestService: DVSRequestService,
    private router: Router,
    private toast: ToastService
  ) {
    if (
      !this.requestService?.dvsRequestDTO?.dvsRequestVinDTOs?.length &&
      !this.requestService?.dvsRequestDTO?.CSVFile
    ) {
      this.requestService.routeBackToStart();
    }
  }

  handleSubmit(event: Event) {
    this.isBusy = true;
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    const nameValid = this.nameValid(formData);
    const emailValid = this.emailValid(formData);
    if (!nameValid || !emailValid) {
      this.isBusy = false;
      return;
    }

    this.requestService.dvsRequestDTO.name = formData.get('name') as string;
    this.requestService.dvsRequestDTO.email = formData.get('email') as string;

    let request = this.dvsRequestService.post(
      this.requestService.dvsRequestDTO
    );

    if (this.requestService.dvsRequestDTO.CSVFile) {
      request = this.dvsRequestService.postCSV(
        this.requestService.dvsRequestDTO
      );
    }

    request
      .subscribe({
        next: (value: string) => {
          this.requestService.submittedId = value;
          this.requestService.clearDVSRequests();
          this.router.navigate(['/submitted']);
        },
        error: () => {
          this.toast.showToast(
            TOAST_VARIANT.error,
            'Error',
            'A server error occurred. Please try again later.'
          );
        },
      })
      .add(() => {
        this.isBusy = false;
      });
  }

  nameValid(formData: FormData): boolean {
    const nameValue = formData.get('name') as string;

    if (!nameValue?.trim().length) {
      this.formState.nameError = 'The Name / Company is required.';
      this.formState.nameState = 'error';
      return false;
    }

    this.formState.nameError = '';
    this.formState.nameState = 'default';
    return true;
  }

  emailValid(formData: FormData): boolean {
    const emailValue = formData.get('email') as string;

    if (!emailValue.length) {
      this.formState.emailError = 'The Email Address is required.';
      this.formState.emailState = 'error';
      return false;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue)) {
      this.formState.emailError = 'Please enter a valid email address.';
      this.formState.emailState = 'error';
      return false;
    }

    this.formState.emailError = '';
    this.formState.emailState = 'default';
    return true;
  }
}
