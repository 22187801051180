<div class="mode-wrapper">
  <div class="mode-variant-wrapper">
    <tds-header>
      <tds-header-title>Direct Vision Standard</tds-header-title>
      <tds-header-brand-symbol slot="end">
        <a routerLink="/" aria-label="Scania - red gryphon on blue shield"></a>
      </tds-header-brand-symbol>
    </tds-header>
  </div>
</div>
