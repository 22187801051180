import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  imports: [RouterLink, TegelModule, CommonModule],
})
export class FooterComponent {
  isProduction: boolean = environment.production;
  currentApplicationVersion = environment.appVersion;
}
