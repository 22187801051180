<div id="header" class="tds-u-pb1">
  <h2 class="tds-headline-02">Direct Vision Standard (DVS) - Star Rating</h2>
  <p class="tds-paragraph-02">
    Find out the DVS Star Rating for a Scania vehicle, using the form below.
  </p>
  <p>
    To learn more about the Direct Vision Standard, and how Scania can support
    you, please
    <a href="https://www.scania.co.uk/dvs" target="_blank">click here</a>
  </p>
</div>

<tds-divider orientation="horizontal"></tds-divider>

<div class="tds-row tds-u-pb3">
  <div class="tds-col-md-12 tds-col-xs-12">
    <h4 class="tds-headline-04">Multiple Vehicles</h4>
  </div>
  <div class="tds-col-md-10 tds-col-xs-8">
    <p class="tds-paragraph-03">
      If you need to request the DVS Star Rating for a large number of vehicles,
      please download, complete, and then upload the provided CSV template.
    </p>
  </div>
  <div class="tds-col-md-2 tds-col-xs-4">
    <tds-button
      fullbleed
      variant="secondary"
      size="md"
      text="Upload a File"
      [routerLink]="'/multiple'"
      [disabled]="dvsRequests.length > 0"
      ><tds-icon
        slot="icon"
        class="tds-u-ml1"
        size="15px"
        name="chevron_right"
      ></tds-icon
    ></tds-button>
  </div>
</div>

<tds-divider orientation="horizontal"></tds-divider>

<div class="tds-row">
  <div class="tds-col-md-12">
    <h4 class="tds-headline-04">Single Vehicle</h4>
  </div>
  <div class="tds-col-md-12 tds-u-pt1 tds-u-pb3">
    <app-vin-info></app-vin-info>
  </div>
  <div class="tds-col-md-12">
    <p class="tds-paragraph-03">
      To request the DVS Star Rating for a smaller number of vehicles
      <b>(up to 50)</b>
      the following form may be used.
    </p>
  </div>
</div>

<form (submit)="handleSubmit($event)" class="tds-u-pb3 tds-u-pt2">
  <div class="tds-row">
    <div class="tds-col-md-5 tds-col-xs-12">
      <tds-text-field
        type="Text"
        name="vin"
        id="vinInput"
        label="Vehicle Identification Number*"
        label-position="outside"
        max-length="17"
        placeholder="Enter 17 Characters"
        [helper]="formState.vinError"
        [state]="formState.vinState"
        [disabled]="dvsRequestsLimitReached"
      >
      </tds-text-field>
    </div>
    <div class="tds-col-md-5 tds-col-xs-12">
      <tds-text-field
        type="Text"
        name="reg"
        id="regInput"
        label="Vehicle Registration"
        label-position="outside"
        max-length="9"
        placeholder="Enter Registration (Optional)"
        [helper]="formState.regError"
        [state]="formState.regState"
        [disabled]="dvsRequestsLimitReached"
      >
      </tds-text-field>
    </div>
    <div class="tds-col-md-2 tds-col-xs-12">
      <tds-button
        fullbleed
        type="submit"
        class="tds-u-mt3"
        variant="primary"
        text="Add"
        [disabled]="dvsRequestsLimitReached"
      ></tds-button>
    </div>
  </div>
  <div class="tds-row tds-u-pt3" *ngIf="dvsRequestsLimitReached">
    <div class="tds-col-md-12">
      <tds-message variant="warning" header="Limit reached" mode-variant=""
        >You have reached the maximum number of requests <b>(50)</b>. Please
        remove some requests before adding new ones.
      </tds-message>
    </div>
  </div>
  <div class="tds-row tds-u-pt3" *ngIf="dvsRequests.length">
    <div class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
      <tds-table responsive no-min-width>
        <tds-table-header>
          <tds-header-cell
            cell-key="vin"
            cell-value="Vehicle Identification Number"
          ></tds-header-cell>
          <tds-header-cell
            cell-key="reg"
            cell-value="Vehicle Registration"
          ></tds-header-cell>
          <tds-header-cell cell-key="action" cell-value=""></tds-header-cell>
        </tds-table-header>
        <tds-table-body>
          <ng-container *ngFor="let request of dvsRequests; let i = index">
            <tds-table-body-row>
              <tds-body-cell
                class="word-break"
                cell-key="vin"
                [attr.cell-value]="request.vin"
              ></tds-body-cell>
              <tds-body-cell
                cell-key="reg"
                [attr.cell-value]="request.registration"
              ></tds-body-cell>
              <tds-body-cell cell-key="action">
                <tds-button
                  size="sm"
                  variant="danger"
                  text="Remove"
                  (click)="removeRequest(i)"
                ></tds-button>
              </tds-body-cell>
            </tds-table-body-row>
          </ng-container>
        </tds-table-body>
      </tds-table>
    </div>
  </div>
  <div class="tds-col-md-12">
    <tds-button
      class="tds-u-mt3"
      variant="secondary"
      text="Next"
      [routerLink]="'/contactdetails'"
      *ngIf="dvsRequests.length"
      ><tds-icon
        slot="icon"
        class="tds-u-ml1"
        size="15px"
        name="chevron_right"
      ></tds-icon
    ></tds-button>
  </div>
</form>
